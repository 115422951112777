<template>
  <div class="manage-groups">
    <div class="card">
      <div class="card-body">
        <b-row>
          <b-col xl="6" lg="6" md="6" sm="12" class="my-1">
            {{ $options.name }}
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "my-groups",
};
</script>

<style scoped></style>
